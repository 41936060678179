var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gallery-section" }, [
    _c("h4", { staticClass: "gallery-section__header" }, [_vm._v("Gallery")]),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "gallery",
        staticClass: "supplier-profile-gallery",
        style: { paddingTop: _vm.grid.height + "%" },
      },
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "supplier-profile-gallery__item",
            class: {
              "supplier-profile-gallery__item--hidden": !_vm.grid.cells[index],
            },
            style: _vm.itemStyle(_vm.grid, index),
            attrs: {
              href: _vm.$blobUrl + "/images/" + item.url,
              "data-caption": item.caption,
            },
          },
          [
            !item.type || item.type === "img"
              ? _c("img", {
                  staticClass: "supplier-profile-gallery__image",
                  attrs: {
                    src: _vm.$blobUrl + "/images/" + item.url,
                    alt: item.caption,
                  },
                })
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }