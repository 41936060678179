<template>
  <div class="gallery-section">
    <h4 class="gallery-section__header">Gallery</h4>
    <div class="supplier-profile-gallery" :style="{ paddingTop: `${grid.height}%` }" ref="gallery">
      <a
        v-for="(item, index) in items"
        :key="index"
        :href="`${$blobUrl}/images/${item.url}`"
        class="supplier-profile-gallery__item"
        :class="{ 'supplier-profile-gallery__item--hidden': !grid.cells[index] }"
        :style="itemStyle(grid, index)"
        :data-caption="item.caption"
      >
        <img
          v-if="!item.type || item.type === 'img'"
          class="supplier-profile-gallery__image"
          :src="`${$blobUrl}/images/${item.url}`"
          :alt="item.caption"
        />
      </a>
    </div>
  </div>
</template>

<script>
// TODO: FIXME: Add support for videos

/*
 * Creates a gallery box with height and grid layout
 * based on number of columns and images. # of columns
 * depends on number of images and width of gallery
 * box.
 *
 * Technically, the box is made based on width ratio
 * for maximal responsiveness. Then, each image is
 * laid out using absolute positioning with %, based
 * upon predefined grid layouts (see `grids` variable
 * below).
 *
 *  Grids variable is a two dimensional collection.
 *  First dimension is number of images. Second
 *  dimension is number of columns. All rounded down
 *  to the largest possible grid.
 *
 */

import baguetteBox from 'baguettebox.js';
import objectFitImages from 'object-fit-images';

import { windowResizeAdd, windowResizeRemove } from 'apps/public/lib/window-resize';

const grids = {
  /* 1 image:
   * -------------
   * |           |
   * |           |
   * ------------- */
  1: {
    1: {
      height: 65,
      cells: [[0, 0, 100, 100]],
    },
  },
  /* 2 images:
   * ------- -------
   * |     | |     |
   * |     | |     |
   * ------- ------- */
  2: {
    2: {
      height: 45,
      cells: [
        [0, 0, 50, 100],
        [50, 0, 50, 100],
      ],
    },
  },
  /* 3 images:
   * --------- ------
   * |       | |    |
   * |       | ------
   * |       | |    |
   * --------- ------ */
  3: {
    2: {
      height: 70,
      cells: [
        [0, 0, 70, 100],
        [70, 0, 30, 55],
        [70, 55, 30, 45],
      ],
    },
  },
  /* 4 images:
   * ------ ------
   * |    | |    |
   * ------ ------
   * |    | |    |
   * ------ ------ */
  4: {
    2: {
      height: 100,
      cells: [
        [0, 0, 50, 55],
        [0, 55, 50, 45],
        [50, 0, 50, 45],
        [50, 45, 50, 55],
      ],
    },
  },
  /* 5 images - 2 columns:
   * ------- ------
   * |     | |    |
   * |     | ------
   * ------- |    |
   * |     | ------
   * |     | |    |
   * ------- ------
   *
   * 5 images - 3 columns:
   * --------- ------
   * |       | |    |
   * --------- ------
   * |  | |  | |    |
   * ---- ---- ------ */
  5: {
    2: {
      height: 120,
      cells: [
        [0, 0, 60, 50],
        [0, 50, 60, 50],
        [60, 0, 40, 34],
        [60, 34, 40, 33],
        [60, 67, 40, 33],
      ],
    },
    3: {
      height: 80,
      cells: [
        [0, 0, 60, 60],
        [0, 60, 30, 40],
        [30, 60, 30, 40],
        [60, 0, 40, 45],
        [60, 45, 40, 55],
      ],
    },
  },
  /* 6 images - 2/3 columns:
   * 2x3 OR 3x2 */
  6: {
    2: {
      height: 150,
      cells: [
        [0, 0, 50, 38],
        [0, 38, 50, 29],
        [0, 67, 50, 33],
        [50, 0, 50, 30],
        [50, 30, 50, 45],
        [50, 75, 50, 25],
      ],
    },
    3: {
      height: 70,
      cells: [
        [0, 0, 40, 60],
        [0, 60, 40, 40],
        [40, 0, 30, 45],
        [40, 45, 30, 55],
        [70, 0, 30, 55],
        [70, 55, 30, 45],
      ],
    },
  },
  /* 7+ images - 2 columns:
   * 2x3 - with +X
   *
   * 7 images - 3 column:
   * ----- ----- -----
   * |   | |   | |   |
   * |   | |   | -----
   * ----- ----- |   |
   * |   | |   | -----
   * |   | |   | |   |
   * ----- ----- -----
   *
   * 7 images - 4 column:
   * ----------- ----- -----
   * |         | |   | |   |
   * |         | ----- |   |
   * ----- ----- |   | -----
   * |   | |   | |   | |   |
   * ----- ----- ----- ----- */
  7: {
    2: {
      height: 150,
      cells: [
        [0, 0, 50, 38],
        [0, 38, 50, 29],
        [0, 67, 50, 33],
        [50, 0, 50, 30],
        [50, 30, 50, 45],
        [50, 75, 50, 25],
      ],
      lastIsX: true,
    },
    3: {
      height: 120,
      cells: [
        [0, 0, 35, 50],
        [0, 50, 35, 50],
        [35, 0, 35, 50],
        [35, 50, 35, 50],
        [70, 0, 30, 34],
        [70, 34, 30, 33],
        [70, 67, 30, 33],
      ],
    },
    4: {
      height: 50,
      cells: [
        [0, 0, 50, 55],
        [0, 55, 25, 45],
        [25, 55, 25, 45],
        [50, 0, 25, 40],
        [50, 40, 25, 60],
        [75, 0, 25, 60],
        [75, 60, 25, 40],
      ],
    },
  },
  /* 8 images - 3 columns:
   * ----------- -------
   * |         | |     |
   * |         | -------
   * ----------- |     |
   * |   | |   | -------
   * ----- ----- |     |
   * |   | |   | |     |
   * ----- ----- -------
   *
   * 8 images - 4 columns:
   * 2x4 */
  8: {
    3: {
      height: 120,
      cells: [
        [0, 0, 64, 48],
        [0, 48, 32, 26],
        [0, 74, 32, 26],
        [32, 48, 32, 26],
        [32, 74, 32, 26],
        [64, 0, 36, 29],
        [64, 29, 36, 29],
        [64, 58, 36, 42],
      ],
    },
    4: {
      height: 50,
      cells: [
        [0, 0, 25, 38],
        [0, 38, 25, 62],
        [25, 0, 23, 62],
        [25, 62, 23, 38],
        [48, 0, 29, 42],
        [48, 42, 29, 58],
        [77, 0, 23, 60],
        [77, 60, 23, 40],
      ],
    },
  },
  /* 9 images - 3 columns:
   * 3x3
   *
   * 9 images - 4 columns:
   * ----------- ----- -----
   * |         | |   | |   |
   * |         | ----- -----
   * |         | |   | |   |
   * ----------- ----- -----
   * |   | |   | |   | |   |
   * ----- ----- ----- ----- */
  9: {
    3: {
      height: 100,
      cells: [
        [0, 0, 34, 28],
        [0, 28, 34, 44],
        [0, 72, 34, 28],
        [34, 0, 33, 38],
        [34, 38, 33, 26],
        [34, 64, 33, 36],
        [67, 0, 33, 30],
        [67, 30, 33, 30],
        [67, 60, 33, 40],
      ],
    },
    4: {
      height: 80,
      cells: [
        [0, 0, 50, 64],
        [0, 64, 25, 36],
        [25, 64, 25, 36],
        [50, 0, 25, 40],
        [50, 40, 25, 22],
        [50, 62, 25, 38],
        [75, 0, 25, 34],
        [75, 34, 25, 33],
        [75, 70, 25, 30],
      ],
    },
  },
  /* 10+ images - 3 columns:
   * 3x3 - with +X
   *
   * 10 images - 4 columns:
   * ----------- ----- -----
   * |         | |   | |   |
   * |         | ----- -----
   * ----------- |   | |   |
   * |   | |   | -----------
   * ----- ----- |         |
   * |   | |   | |         |
   * ----- ----- ----------- */
  10: {
    3: {
      height: 100,
      cells: [
        [0, 0, 34, 28],
        [0, 28, 34, 44],
        [0, 72, 34, 28],
        [34, 0, 33, 38],
        [34, 38, 33, 26],
        [34, 64, 33, 36],
        [67, 0, 33, 30],
        [67, 30, 33, 30],
        [67, 60, 33, 40],
      ],
      lastIsX: true,
    },
    4: {
      height: 100,
      cells: [
        [0, 0, 50, 45],
        [0, 45, 25, 30],
        [0, 75, 25, 25],
        [25, 45, 25, 27],
        [25, 72, 25, 28],
        [50, 0, 25, 33],
        [50, 33, 25, 27],
        [50, 60, 50, 40],
        [75, 0, 25, 30],
        [75, 30, 25, 30],
      ],
    },
  },
  /* 11 images - 4 columns:
   * ----------- ----- -----
   * |         | |   | |   |
   * |         | ----- -----
   * ----------- |   | |   |
   * |   | |   | ----- -----
   * ----- ----- |   | |   |
   * |   | |   | |   | |   |
   * ----- ----- ----- ----- */
  11: {
    4: {
      height: 100,
      cells: [
        [0, 0, 50, 45],
        [0, 45, 25, 30],
        [0, 75, 25, 25],
        [25, 45, 25, 27],
        [25, 72, 25, 28],
        [50, 0, 25, 33],
        [50, 33, 25, 37],
        [50, 70, 25, 30],
        [75, 0, 25, 26],
        [75, 26, 25, 28],
        [75, 54, 25, 46],
      ],
    },
  },
  /* 12 images - 4 columns:
   * 4x3 */
  12: {
    4: {
      height: 85,
      cells: [
        [0, 0, 26, 28],
        [0, 28, 26, 44],
        [0, 72, 26, 28],
        [26, 0, 23, 38],
        [26, 38, 23, 26],
        [26, 64, 23, 36],
        [49, 0, 29, 30],
        [49, 30, 29, 30],
        [49, 60, 29, 40],
        [78, 0, 22, 44],
        [78, 44, 22, 26],
        [78, 70, 22, 30],
      ],
    },
  },
  /* 13 + images - 4 columns:
   * 4x3 - with +X */
  13: {
    4: {
      height: 85,
      cells: [
        [0, 0, 26, 28],
        [0, 28, 26, 44],
        [0, 72, 26, 28],
        [26, 0, 23, 38],
        [26, 38, 23, 26],
        [26, 64, 23, 36],
        [49, 0, 29, 30],
        [49, 30, 29, 30],
        [49, 60, 29, 40],
        [78, 0, 22, 44],
        [78, 44, 22, 26],
        [78, 70, 22, 30],
      ],
      lastIsX: true,
    },
  },
};

export default {
  props: {
    supplier: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      width: null,
    };
  },
  computed: {
    columns() {
      const items = this.items.length;
      const width = this.width;

      if (width > 980 && items >= 7) return 4;
      if (width > 700 && items >= 5) return 3;
      if (items > 1) return 2;
      return 1;
    },
    items() {
      return this.supplier.gallery || [];
    },
    grid() {
      const items = Math.min(13, this.items.length);
      const columns = this.columns;
      let grid;

      for (let index = items; index > 0 && !grid; index--) {
        grid = grids[index][columns];
      }

      return grid || grids[1][1];
    },
  },
  methods: {
    itemStyle(grid, index) {
      if (!grid.cells[index]) return {};

      return {
        left: `${grid.cells[index][0]}%`,
        top: `${grid.cells[index][1]}%`,
        width: `${grid.cells[index][2]}%`,
        height: `${grid.cells[index][3]}%`,
      };
    },
    updateWidths() {
      if (!this.$refs.gallery) return; // Fixes HMR issues

      this.width = this.$refs.gallery.clientWidth;
    },
  },
  mounted() {
    this.updateWidths();
    windowResizeAdd(this.updateWidths);
    baguetteBox.run('.supplier-profile-gallery');
    // Does not support re-rendering (which probably could happen
    // when resizing the window) - but that's fine for IE11.
    objectFitImages('.supplier-profile-gallery__image');
  },
  beforeDestroy() {
    windowResizeRemove(this.updateWidths);
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
@import 'common/styles/text';
@import 'baguettebox.js/dist/baguetteBox.min.css';

.supplierProfile {
  .gallery-section {
    &__header {
      @include heading--small;
      text-align: center;
      margin-top: 0;
      padding-top: 3.125rem;
    }
  }
}

.supplier-profile-gallery {
  position: relative;
  margin: 0 -0.8125rem;

  &__item {
    position: absolute;
    padding: 0.875rem;

    &--hidden {
      display: none;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;'; // IE11 fix - together with JS in mounted;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  // Taken straight from pages/gallery-grid.vue
  &__item:hover &__image {
    opacity: 0.8;
    transform: scale(1.05);
  }
}

// Taken straight from pages/gallery-grid.vue
// TODO: REFACTOR/merge this with pages/gallery-grid.vue ???
// Baguette lightbox
#baguetteBox-overlay {
  z-index: 99999999 !important;
}
</style>
